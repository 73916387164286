:root {
  --dark-bg: linear-gradient(-225deg, rgb(24, 30, 33) 0%, rgb(34, 44, 51) 50%);
  --gray-bg: #f7f7f7;
  --dark: #282e31;
  --gray: #bec3c2;
  --mdc-layout-grid-gutter: 40px;
  scroll-behavior: smooth;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.3px;
  color: var(--dark);
  margin: 0;
}

h1 {
  font-size: 68px;
}
h2 {
  font-size: 26px;
  line-height: 1.5;
}
h3 {
  font-size: 21px;
  letter-spacing: -0.5px;
  line-height: 30px;
}
h4 {
  font-size: 17px;
  line-height: 26px;
}
p {
  font-size: 16px;
  line-height: 1.6;
}

a {
  color: var(--dark);
}

#map {
  width: 100%;
  height: 600px;
  margin-top: 20px;
}

/* #map div div div:last-child div:last-child div div div:nth-child(3) { */
/* left: 0 !important; */
/* top: 30px !important; */
/* border-top-width: 24px; */
/* position: absolute; */
/* transform: rotate(90deg); */
/* display: none; */
/* } */
.gm-style-iw-t::before,
.gm-style-iw-t::after {
  display: none;
}

.text-center {
  text-align: center;
}

.map-infoWindow {
  text-align: left;
  color: var(--dark);
}

.map-infoWindow p {
  font-size: 15px;
}

.map-infoWindow h3 {
  margin-bottom: 0;
  font-size: 18px;
}

.map-infoWindow .location {
  font-size: 13px;
  opacity: 0.5;
  margin-top: 6px;
}

#cover-video {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#hero-filter {
  background-color: rgba(27, 32, 35, 0.5);
  height: 100vh;
  position: absolute;
}

#hero {
  /*background-color: var(--dark);*/
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: white;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  text-align: center;
  position: relative;
}

#hero .subtitle {
  max-width: 100%;
}
#hero .subtitle-p {
  font-size: 24px;
  color: #e3e6e6;
  line-height: 40px;
}

#hero .logo {
  position: absolute;
  top: 60px;
  left: 80px;
  font-size: 20px;
  text-transform: uppercase;
  opacity: 0.8;
  margin: 0;
}

#hero .text {
  padding: 20px;
  text-align: center;
}

#hero h2 {
  margin: 0;
  font-size: 44px;
  max-width: 850px;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.25;
}

#hero img {
  margin-top: 10px;
}

#hero .menu {
  position: absolute;
  top: 60px;
  right: 80px;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: -0.3px;
}

#hero .menu a {
  color: white;
  text-decoration: none;
  padding-left: 15px;
  opacity: 0.8;
  transition: opacity 200ms;
}

#hero .menu a:hover {
  opacity: 1;
}

#impact {
  padding: 60px 0 40px 0;
}

#skills {
  padding: 80px 0;
}

#skills-text {
  text-align: center;
}

#skills-list .skill-img {
  min-height: 100px;
  width: auto;
}

#skills-list {
  margin-top: 40px;
}

#skills-list p {
  margin-bottom: 20px;
}

.text-lite {
  color: var(--dark);
  opacity: 0.7;
}

.text-small {
  font-size: 14px;
}

#speaking {
  background: var(--dark-bg);
  padding-top: 20px;
  color: #d8d8d8;
  text-align: center;
}

#speaking h2 {
  margin-top: 0;
}

#speaking a {
  color: white;
}

.speaking-text {
  color: white;
  opacity: 0.6;
  max-width: 600px;
  margin: 0 auto;
}

.inner-grid {
  padding-top: 0;
  padding-bottom: 0;
}

#talks {
  padding: 40px 0 60px 0;
}

#talks h2 {
  text-align: center;
}
#talks h3 {
  margin-top: 0;
}

.banner {
  background: var(--gray-bg);
  padding: 20px 0;
}

.banner .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  height: 100%;
}

.button {
  text-transform: uppercase;
  min-width: 100px;
  text-align: center;
  font-weight: bold;
  padding: 15px 35px;
  border: 1px solid var(--dark);
  color: var(--dark);
  text-decoration: none;
  display: inline-block;
  transition: background-color 200ms, color 200ms;
}

.button:hover {
  background-color: var(--dark);
  color: white;
}

#blog {
  padding: 40px 0 60px 0;
}
#blog h2 {
  text-align: center;
}

#awards {
  background-color: var(--dark);
  color: white;
  text-align: center;
  padding: 40px 0 60px 0;
}

#awards a {
  color: white;
  text-decoration: none;
}

.awards-list {
  padding-top: 30px;
}

.award-title {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: bold;
}

.award-category {
  margin-top: 0;
}

.award-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

a:hover {
  text-decoration: underline !important;
}

footer {
  background-color: rgba(34, 44, 51, 0.98);
}

footer .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

footer .icons {
  display: flex;
}

footer .icons img {
  padding-right: 15px;
  opacity: 0.5;
  transition: opacity 200ms;
}

footer .icons img:hover {
  opacity: 0.7;
}

footer .text {
  color: white;
  opacity: 0.7;
  font-size: 13px;
  text-align: right;
  line-height: 20px;
  margin: 0;
}

footer .text a {
  color: white;
  transition: opacity 200ms;
}

#trusted-by {
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
  background-color: var(--gray-bg);
}

#trusted-by img {
  padding: 15px;
  transition: opacity 200ms ease-out;
}

#trusted-by img:hover {
  opacity: 0.9;
}

#trusted-by .row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
  justify-items: center;
  align-items: center;
}
#trusted-by .incomplete-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 100px;
  justify-items: center;
  align-items: center;
}

@media (max-width: 700px) {
  #trusted-by .row,
  #trusted-by .incomplete-row {
    grid-template-columns: 1fr;
  }
}

.course-entry {
  padding-bottom: 30px;
}

.course-title {
  display: flex;
  align-items: center;
}
.course-icon {
  margin-right: 10px;
}

@media (max-width: 839px) {
  #hero h2 {
    font-size: 50px;
  }
  h2 {
    font-size: 24px;
  }
  .mdc-layout-grid__cell--span-2 {
    display: none;
  }
  #hero .logo {
    left: 30px;
    top: 30px;
  }
  #hero .menu {
    right: 30px;
    top: 30px;
  }
}

@media (max-width: 700px) {
  #hero h2 {
    font-size: 36px;
  }
  footer .icons img {
    width: 20px;
  }
}

#video-wrapper {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
}

#video-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-image: linear-gradient(
    -225deg,
    rgba(16, 33, 43, 0.8) 0%,
    rgba(24, 43, 56, 0.6) 50%
  );
  z-index: 10;
}

video {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: linear-gradient(
    -225deg,
    rgba(16, 33, 43, 0.8) 0%,
    rgba(24, 43, 56, 0.6) 50%
  );
}

#hero .content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 700px) {
  .menu-hide-mobile {
    display: none;
  }
}

@media (max-width: 450px) {
  .menu {
    display: none;
  }
  .menu-twitter {
    display: none;
  }
}
@media (max-width: 380px) {
  .menu-contact {
    display: none;
  }
}

::-moz-selection {
  background: rgb(34, 44, 51);
  color: white;
}
::selection {
  background: rgb(34, 44, 51);
  color: white;
}

#talks .workshop-logo {
  display: flex;
  align-items: center;
  height: 100%;
}

#youtube {
  background-image: var(--dark-bg);
  color: white;
  padding-top: 40px;
  padding-bottom: 60px;
}

#youtube .latest-video {
  max-width: 500px;
  margin: 40px auto 0 auto;
}
@media (max-width: 750px) {
  #youtube iframe {
    width: 100%;
  }
}

#open-source {
  padding-top: 80px;
  padding-bottom: 80px;
}
#open-source h2 {
  margin-bottom: 30px;
}

.project {
  text-decoration: none;
  display: block;
  margin-bottom: 30px;
}

.project img {
  box-shadow: 2px 2px 2px #0000001a, -1px -1px 1px #0000001a;
  border-radius: 3px;
}

.project-info {
  margin-left: 30px;
}
.project-info p {
  color: var(--dark);
  opacity: 0.9;
  margin-top: 10px;
}
.project-info h4 {
  margin-top: 0;
  margin-bottom: 0;
}

#contact {
  background-image: var(--dark-bg);
  color: white;
  padding: 60px 0;
  text-align: center;
}

#contact-notice {
  display: inline-block;
  background-color: white;
  color: #222;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  opacity: 0.95;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 18px;
}

#gde {
  background-image: url("https://res.cloudinary.com/dbfn5lnvx/image/upload/f_auto,q_auto:good/v1540372019/jadjoubran.io/gde-2018.png");
  background-size: cover;
  width: 100px;
  height: 118px;
  position: absolute;
  bottom: 50px;
  right: 50px;
  z-index: 100;
}

@media (max-width: 400px) {
  #gde {
    left: 50%;
    margin-left: -50px;
  }
}

.youtube-player {
  position: relative;
  /* padding-bottom: 32%; */
  height: 281px;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 5px;
}

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
}

.youtube-player img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  transition: 0.4s all;
}

.youtube-player img:hover {
  -webkit-filter: brightness(75%);
  filter: brightness(75%);
}

.youtube-player .play {
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  position: absolute;
  background: url("/assets/play.svg") no-repeat;
  cursor: pointer;
}

#contact-form {
  margin-top: 20px;
}
#contact-form label {
  font-weight: bold;
}

#contact-form input:not([type="checkbox"]),
#contact-form textarea {
  font-family: inherit;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  border: 0;
  font-weight: normal;
  border-radius: 2px;
  width: 100%;
  max-width: 350px;
  font-size: 16px;
}

#contact-form input[type="checkbox"] {
  margin-bottom: 20px;
}

#contact-form button {
  box-shadow: none;
  border: 0;
  border-radius: 3px;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 150ms ease-out, transform 150ms ease-out;
}
#contact-form button:hover {
  opacity: 0.8;
}
#contact-form button:active {
  transform: translate(1px, 1px);
}

.contact-page-container {
  max-width: 900px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.grid-talks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding-top: 30px;
}

.grid-project {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
}

@media (max-width: 1023px) {
  .container {
    max-width: 100%;
    margin: 0 15px;
  }

  .grid-3 {
    grid-template-columns: 1fr;
  }
  .grid-2 {
    grid-template-columns: 1fr;
  }
  .grid-talks {
    grid-template-columns: 1fr;
  }
  .grid-project {
    grid-template-columns: 1fr;
  }

  .banner .right {
    justify-content: flex-start;
    text-align: left;
  }
}
