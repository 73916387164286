:root {
  --dark-bg: linear-gradient(-225deg, #181e21 0%, #222c33 50%);
  --gray-bg: #f7f7f7;
  --dark: #282e31;
  --gray: #bec3c2;
  --mdc-layout-grid-gutter: 40px;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  letter-spacing: -.3px;
  color: var(--dark);
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1 {
  font-size: 68px;
}

h2 {
  font-size: 26px;
  line-height: 1.5;
}

h3 {
  letter-spacing: -.5px;
  font-size: 21px;
  line-height: 30px;
}

h4 {
  font-size: 17px;
  line-height: 26px;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

a {
  color: var(--dark);
}

#map {
  width: 100%;
  height: 600px;
  margin-top: 20px;
}

.gm-style-iw-t:before, .gm-style-iw-t:after {
  display: none;
}

.text-center {
  text-align: center;
}

.map-infoWindow {
  text-align: left;
  color: var(--dark);
}

.map-infoWindow p {
  font-size: 15px;
}

.map-infoWindow h3 {
  margin-bottom: 0;
  font-size: 18px;
}

.map-infoWindow .location {
  opacity: .5;
  margin-top: 6px;
  font-size: 13px;
}

#cover-video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

#hero-filter {
  background-color: #1b202380;
  height: 100vh;
  position: absolute;
}

#hero {
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: relative;
}

#hero .subtitle {
  max-width: 100%;
}

#hero .subtitle-p {
  color: #e3e6e6;
  font-size: 24px;
  line-height: 40px;
}

#hero .logo {
  text-transform: uppercase;
  opacity: .8;
  margin: 0;
  font-size: 20px;
  position: absolute;
  top: 60px;
  left: 80px;
}

#hero .text {
  text-align: center;
  padding: 20px;
}

#hero h2 {
  letter-spacing: -.5px;
  max-width: 850px;
  margin: 0 auto 30px;
  font-size: 44px;
  line-height: 1.25;
}

#hero img {
  margin-top: 10px;
}

#hero .menu {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: -.3px;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  top: 60px;
  right: 80px;
}

#hero .menu a {
  color: #fff;
  opacity: .8;
  padding-left: 15px;
  text-decoration: none;
  transition: opacity .2s;
}

#hero .menu a:hover {
  opacity: 1;
}

#impact {
  padding: 60px 0 40px;
}

#skills {
  padding: 80px 0;
}

#skills-text {
  text-align: center;
}

#skills-list .skill-img {
  width: auto;
  min-height: 100px;
}

#skills-list {
  margin-top: 40px;
}

#skills-list p {
  margin-bottom: 20px;
}

.text-lite {
  color: var(--dark);
  opacity: .7;
}

.text-small {
  font-size: 14px;
}

#speaking {
  background: var(--dark-bg);
  color: #d8d8d8;
  text-align: center;
  padding-top: 20px;
}

#speaking h2 {
  margin-top: 0;
}

#speaking a {
  color: #fff;
}

.speaking-text {
  color: #fff;
  opacity: .6;
  max-width: 600px;
  margin: 0 auto;
}

.inner-grid {
  padding-top: 0;
  padding-bottom: 0;
}

#talks {
  padding: 40px 0 60px;
}

#talks h2 {
  text-align: center;
}

#talks h3 {
  margin-top: 0;
}

.banner {
  background: var(--gray-bg);
  padding: 20px 0;
}

.banner .right {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  display: flex;
}

.button {
  text-transform: uppercase;
  text-align: center;
  border: 1px solid var(--dark);
  color: var(--dark);
  min-width: 100px;
  padding: 15px 35px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .2s, color .2s;
  display: inline-block;
}

.button:hover {
  background-color: var(--dark);
  color: #fff;
}

#blog {
  padding: 40px 0 60px;
}

#blog h2 {
  text-align: center;
}

#awards {
  background-color: var(--dark);
  color: #fff;
  text-align: center;
  padding: 40px 0 60px;
}

#awards a {
  color: #fff;
  text-decoration: none;
}

.awards-list {
  padding-top: 30px;
}

.award-title {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: bold;
}

.award-category {
  margin-top: 0;
}

.award-image-container {
  justify-content: center;
  align-items: center;
  height: 150px;
  display: flex;
}

a:hover {
  text-decoration: underline !important;
}

footer {
  background-color: #222c33fa;
}

footer .wrapper {
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  display: flex;
}

footer .icons {
  display: flex;
}

footer .icons img {
  opacity: .5;
  padding-right: 15px;
  transition: opacity .2s;
}

footer .icons img:hover {
  opacity: .7;
}

footer .text {
  color: #fff;
  opacity: .7;
  text-align: right;
  margin: 0;
  font-size: 13px;
  line-height: 20px;
}

footer .text a {
  color: #fff;
  transition: opacity .2s;
}

#trusted-by {
  background-color: var(--gray-bg);
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
}

#trusted-by img {
  padding: 15px;
  transition: opacity .2s ease-out;
}

#trusted-by img:hover {
  opacity: .9;
}

#trusted-by .row {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
  place-items: center;
  display: grid;
}

#trusted-by .incomplete-row {
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 100px;
  place-items: center;
  display: grid;
}

@media (width <= 700px) {
  #trusted-by .row, #trusted-by .incomplete-row {
    grid-template-columns: 1fr;
  }
}

.course-entry {
  padding-bottom: 30px;
}

.course-title {
  align-items: center;
  display: flex;
}

.course-icon {
  margin-right: 10px;
}

@media (width <= 839px) {
  #hero h2 {
    font-size: 50px;
  }

  h2 {
    font-size: 24px;
  }

  .mdc-layout-grid__cell--span-2 {
    display: none;
  }

  #hero .logo {
    top: 30px;
    left: 30px;
  }

  #hero .menu {
    top: 30px;
    right: 30px;
  }
}

@media (width <= 700px) {
  #hero h2 {
    font-size: 36px;
  }

  footer .icons img {
    width: 20px;
  }
}

#video-wrapper {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: relative;
  inset: 0;
  overflow: hidden;
}

#video-overlay {
  z-index: 10;
  background-image: linear-gradient(-225deg, #10212bcc 0%, #182b3899 50%);
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: absolute;
  inset: 0;
}

video {
  z-index: 0;
  object-fit: cover;
  background-image: linear-gradient(-225deg, #10212bcc 0%, #182b3899 50%);
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

#hero .content {
  z-index: 20;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

@media (width <= 700px) {
  .menu-hide-mobile {
    display: none;
  }
}

@media (width <= 450px) {
  .menu, .menu-twitter {
    display: none;
  }
}

@media (width <= 380px) {
  .menu-contact {
    display: none;
  }
}

::selection {
  color: #fff;
  background: #222c33;
}

#talks .workshop-logo {
  align-items: center;
  height: 100%;
  display: flex;
}

#youtube {
  background-image: var(--dark-bg);
  color: #fff;
  padding-top: 40px;
  padding-bottom: 60px;
}

#youtube .latest-video {
  max-width: 500px;
  margin: 40px auto 0;
}

@media (width <= 750px) {
  #youtube iframe {
    width: 100%;
  }
}

#open-source {
  padding-top: 80px;
  padding-bottom: 80px;
}

#open-source h2 {
  margin-bottom: 30px;
}

.project {
  margin-bottom: 30px;
  text-decoration: none;
  display: block;
}

.project img {
  border-radius: 3px;
  box-shadow: 2px 2px 2px #0000001a, -1px -1px 1px #0000001a;
}

.project-info {
  margin-left: 30px;
}

.project-info p {
  color: var(--dark);
  opacity: .9;
  margin-top: 10px;
}

.project-info h4 {
  margin-top: 0;
  margin-bottom: 0;
}

#contact {
  background-image: var(--dark-bg);
  color: #fff;
  text-align: center;
  padding: 60px 0;
}

#contact-notice {
  color: #222;
  text-align: center;
  opacity: .95;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 15px;
  font-size: 18px;
  display: inline-block;
}

#gde {
  z-index: 100;
  background-image: url("https://res.cloudinary.com/dbfn5lnvx/image/upload/f_auto,q_auto:good/v1540372019/jadjoubran.io/gde-2018.png");
  background-size: cover;
  width: 100px;
  height: 118px;
  position: absolute;
  bottom: 50px;
  right: 50px;
}

@media (width <= 400px) {
  #gde {
    margin-left: -50px;
    left: 50%;
  }
}

.youtube-player {
  background: #000;
  max-width: 100%;
  height: 281px;
  margin: 5px;
  position: relative;
  overflow: hidden;
}

.youtube-player iframe {
  z-index: 100;
  background: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.youtube-player img {
  cursor: pointer;
  border: none;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  transition: all .4s;
  display: block;
  position: absolute;
  inset: 0;
}

.youtube-player img:hover {
  filter: brightness(75%);
}

.youtube-player .play {
  cursor: pointer;
  background: url("play.9b75458c.svg") no-repeat;
  width: 72px;
  height: 72px;
  margin-top: -36px;
  margin-left: -36px;
  position: absolute;
  top: 50%;
  left: 50%;
}

#contact-form {
  margin-top: 20px;
}

#contact-form label {
  font-weight: bold;
}

#contact-form input:not([type="checkbox"]), #contact-form textarea {
  border: 0;
  border-radius: 2px;
  width: 100%;
  max-width: 350px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  font-family: inherit;
  font-size: 16px;
  font-weight: normal;
}

#contact-form input[type="checkbox"] {
  margin-bottom: 20px;
}

#contact-form button {
  box-shadow: none;
  cursor: pointer;
  border: 0;
  border-radius: 3px;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  transition: opacity .15s ease-out, transform .15s ease-out;
}

#contact-form button:hover {
  opacity: .8;
}

#contact-form button:active {
  transform: translate(1px, 1px);
}

.contact-page-container {
  max-width: 900px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}

.grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  display: grid;
}

.grid-2 {
  grid-template-columns: 1fr 1fr;
  align-items: center;
  display: grid;
}

.grid-talks {
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding-top: 30px;
  display: grid;
}

.grid-project {
  grid-template-columns: 250px 1fr;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
  display: grid;
}

@media (width <= 1023px) {
  .container {
    max-width: 100%;
    margin: 0 15px;
  }

  .grid-3, .grid-2, .grid-talks, .grid-project {
    grid-template-columns: 1fr;
  }

  .banner .right {
    text-align: left;
    justify-content: flex-start;
  }
}

/*# sourceMappingURL=contact.7f437c74.css.map */
